const ChartPlugin = (md) => {
  const temp = md.renderer.rules.fence.bind(md.renderer.rules);
  md.renderer.rules.fence = (tokens, idx, options, env, slf) => { // eslint-disable-line
    const token = tokens[idx];
    if (token.info === 'mermaid' || token.info === 'chart') {
      const content = token.content.trim();
      if (token.info === 'mermaid') {
        if (content.startsWith('image: ')) {
          const c = content.split('\n');
          const img = c[0].substring(('image: ').length);
          const imgtype = c[1].toLowerCase().replace(/[\W_]+/g, '-');
          return `<img class='mermaid-chart mermaid-${imgtype}' src='${img}' alt='' />`;
        }
        return `<div class='mermaid'>${content}</div>`;
      }
      if (token.info === 'chart') {
        if (content.startsWith('image: ')) {
          const img = content.split('\n')[0];
          return `<img class='chartjs' src='${img.substring(('image: ').length)}' alt='' />`;
        }
        return `<canvas class='chartjs'>${content}</canvas>`;
      }
    }
    return temp(tokens, idx, options, env, slf);
  };
};

module.exports = ChartPlugin;
