import Prism from 'prismjs';
import MarkdownIt from 'markdown-it';
import MarkdownItSup from 'markdown-it-sup';
import MarkdownItSub from 'markdown-it-sub';
import MarkdownItTasks from 'markdown-it-task-lists';
import MarkdownItEmoji from 'markdown-it-emoji';
// import MarkdownItReplaceLink from 'markdown-it-replace-link';
import MarkdownItHashtag from 'markdown-it-hashtag';
import Twemoji from 'twemoji';
import MarkdownItMentions from '@/modules/markdownmentions';
import MarkdownItCharts from '@/modules/markdowncharts';

let vm;

const getField = (s) => {
  const path = s.split('-');
  if (path.length === 1) {
    return { o: vm, name: path[0] };
  }
  let o = vm;
  for (let i = 0; i < path.length - 1; i += 1) {
    o = o[path[i]];
  }
  return { o, name: path[path.length - 1] };
};

const md = MarkdownIt({
  typographer: true,
  highlight(s, lang) {
    let hl;
    try {
      hl = Prism.highlight(s, Prism.languages[lang]);
    } catch (e) {
      hl = md.utils.escapeHtml(s);
    }
    return `<pre class="language-${lang}"><code class="language-${lang}">${hl}</code></pre>`;
  },
  replaceLink(link) {
    if (vm.$route.params.group && vm.$route.params.project) {
      if (link.startsWith('/uploads') || link.startsWith('/files')) {
        return `${vm.$api.defaults.baseURL}/${vm.$route.params.group}/${vm.$route.params.project}${link}`;
      }
    }
    return link;
  },
});
md.use(MarkdownItSup);
md.use(MarkdownItSub);
md.use(MarkdownItTasks);
md.use(MarkdownItEmoji);
md.renderer.rules.emoji = (token, i) => Twemoji.parse(token[i].content);
// md.use(MarkdownItReplaceLink);
md.use(MarkdownItMentions);
md.use(MarkdownItCharts);
md.use(MarkdownItHashtag, { hashtagRegExp: '\\d+' });
md.renderer.rules.hashtag_open = (tokens, idx) => {
  if (vm.$route.params.group && vm.$route.params.project) {
    // TODO do not link issues that do not exist
    const issuenum = tokens[idx].content;
    return `<a href="/${vm.$route.params.group}/${vm.$route.params.project}/issues/${issuenum}">`;
  }
  return '';
};

//
// editor
//

md.config = (t, options) => {
  vm = t;
  if (options) {
    const keys = Object.keys(options);
    for (let i = 0; i < keys.length; i += 1) {
      if (keys[i] !== 'linkify') {
        md.options[keys[i]] = options[keys[i]];
      }
    }
  }
};

md.textarea = (r) => {
  const o = {
    insert: (newLine, content, selectedContent, afterCursorContent) => {
      let el = vm.$refs[r];
      if (el.$el) {
        el = el.$el;
      }
      const data = getField(r);
      const start = el.selectionStart;
      const end = el.selectionEnd;
      let bnl = newLine;
      let enl = newLine;
      if (start === 0 || el.value[start - 1] === '\n') {
        bnl = false;
      }
      if (end === el.value.length || el.value[end + 1] === '\n') {
        enl = false;
      }
      data.o[data.name] = el.value.substring(0, start) + (bnl ? '\n\n' : '') + content + (!selectedContent ? '' : selectedContent) + (!afterCursorContent ? '' : afterCursorContent) + (enl ? '\n\n' : '') + el.value.substring(end, el.value.length);
      vm.$nextTick(() => {
        el.selectionStart = start + (bnl ? 2 : 0) + content.length;
        el.selectionEnd = el.selectionStart;
        if (selectedContent) {
          el.selectionEnd += selectedContent.length;
        }
        el.focus();
      });
    },
    selection: () => {
      let el = vm.$refs[r];
      if (el.$el) {
        el = el.$el;
      }
      return el.value.substring(el.selectionStart, el.selectionEnd);
    },
  };
  return o;
};

md.insert = (r) => {
  const o = {
    header: () => { md.textarea(r).insert(true, `### ${md.textarea(r).selection()}`); },
    bold: () => { md.textarea(r).insert(false, `**${md.textarea(r).selection()}`, '', '**'); },
    italic: () => { md.textarea(r).insert(false, `*${md.textarea(r).selection()}`, '', '*'); },
    quote: () => { md.textarea(r).insert(true, `> ${md.textarea(r).selection()}`); },
    code: () => { md.textarea(r).insert(false, `\`${md.textarea(r).selection()}`, '', '`'); },
    image: (name, url) => {
      if (name && url) {
        md.textarea(r).insert(false, `![${decodeURIComponent(name)}](${url})`, '', '');
      } else {
        md.textarea(r).insert(false, `![${md.textarea(r).selection()}](`, 'url', ')');
      }
    },
    link: (name, url) => {
      if (name && url) {
        md.textarea(r).insert(false, `[${decodeURIComponent(name)}](${url})`, '', '');
      } else {
        md.textarea(r).insert(false, `[${md.textarea(r).selection()}](`, 'url', ')');
      }
    },
    ul: () => { md.textarea(r).insert(true, `* ${md.textarea(r).selection()}`); },
    ol: () => { md.textarea(r).insert(true, `1. ${md.textarea(r).selection()}`); },
    task: () => { md.textarea(r).insert(true, `- [ ] ${md.textarea(r).selection()}`); },
    table: () => { md.textarea(r).insert(true, `| header | header |\n| ------ | ------ |\n| cell | cell |\n| cell | cell |\n${md.textarea(r).selection()}`); },
  };
  return o;
};

export default md;
