// TODO check if username exists
const renderMention = (tokens, idx, options) => {
  if (options.mention && tokens[idx].tag === '') {
    return `<b>@${tokens[idx].content}</b>`;
  } if (tokens[idx].tag) {
    return `<${tokens[idx].tag}>@${tokens[idx].content}</${tokens[idx].tag}>`;
  }
  return `@${tokens[idx].content}`;
};

const parseMentions = (state) => {
  const matcher = /(?:^|\s)@([a-zA-Z0-9-]){1,39}\b/;

  state.tokens.forEach((blockToken) => {
    if (blockToken.type !== 'inline') return;

    const { children } = blockToken;

    children.forEach((token, idx) => {
      const matchToken = children[idx];

      if (!matcher.test(matchToken.content)) return;

      const arr = matchToken.content.split(/\b/);

      // get index of @ and remove @ from string
      let k;
      for (k = 0; k < arr.length - 1; k += 1) {
        if (arr[k][arr[k].length - 1] === '@') {
          arr[k] = arr[k].slice(0, -1);
          break;
        }
      }

      const pre = arr.slice(0, k + 1).join('');
      const username = arr[k + 1];
      const post = arr.slice(k + 2, arr.length).join('');

      const mentionToken = Object.assign({}, matchToken); // eslint-disable-line
      const postToken = Object.assign({}, matchToken); // eslint-disable-line

      matchToken.content = pre;
      postToken.content = post;
      mentionToken.type = 'mention';
      mentionToken.content = username;

      children.splice(idx + 1, 0, mentionToken);
      children.splice(idx + 2, 0, postToken);
    });

    blockToken.children = children; // eslint-disable-line
  });
};

export default (md) => {
  md.renderer.rules.mention = renderMention; // eslint-disable-line
  md.core.ruler.after('inline', 'mention', parseMentions); // eslint-disable-line
};
